const initialState = {
    loading: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case 'SUBSCRIBE_LOADING':
            return { ...state, loading: payload }
        case 'SUBSCRIBE_SUCCESS':
            return { ...state, loading: false }
        case 'SUBSCRIBE_ERROR':
            return { ...state, loading: false }

        default:
            return state
    }
}
