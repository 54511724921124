import React from 'react'
import { Helmet } from 'react-helmet'

const Seo = ({ title, description, image, url }) => {
    return (
        <Helmet>
            {/* SEO Balises de base */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={url} />


            {/* Open Graph pour Meta/Facebook */}
            <meta property="og:type" content="article" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:site_name" content="Voice Of Congo" />

            {/* Twitter Card */}
            <meta name="twitter:card" content={"summary_large_image"} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:site" content="@VoiceOfCongo" />
        </Helmet>
    )
}

export default Seo