import { Box, Container, Grid2, Typography } from '@mui/material'
import React from 'react'
import Slider from 'react-slick'

const Videos = ({ videos }) => {
    var settings = {
        dots: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        autoplay: true,
        speed: 200,
        autoplaySpeed: 3000,
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Box>
            <Container sx={{ p: 5 }}>
                <Grid2 container spacing={2}>
                    <Grid2 item size={12}>
                        <Typography sx={{ my: 3 }} textAlign={'center'} variant='h3'>Vidéos</Typography>
                    </Grid2>
                    <Grid2 item size={12}>
                        <Slider {...settings} >
                            {videos && videos.map(item => (
                                <Typography textAlign={'center'}>
                                    <iframe
                                        width="225"
                                        height="400"
                                        src={'https://youtube.com/embed/' + item.youtubeVideoID}
                                        style={{ border: 0 }}
                                        title={item.title}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </Typography>
                            ))}
                        </Slider>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default Videos