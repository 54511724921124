import ArticleItem from "./Item";
import React, { useEffect, useState } from 'react'
import { Container, Grid2, Typography } from '@mui/material'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { db } from "../../firebase";

const MoreArticles = ({ rubrique }) => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (!db) return; // Attendre que Firestore soit initialisé
        const colRef = collection(db, "articles");
        const q = rubrique ? query(
            colRef,
            where("rubrique", "==", rubrique),
            orderBy("createdAt", "desc")
        ) : query(
            colRef,
            orderBy("createdAt", "desc")
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            let content = [];
            if (snapshot.docs) {
                snapshot.docs.forEach((doc) =>
                    content.push({ id: doc.id, ...doc.data() })
                );
                setArticles(content);
            }
        });
        // Unsubscribe from events when no longer in use
        return () => unsubscribe();
    }, [rubrique]);

    return (
        <Container>
            <Grid2 container spacing={2} sx={{ my: 3 }}>
                {articles && <Grid2 item size={12}>
                    <Typography variant='h3' color={'primary'}>PLUS D'ARTICLES {rubrique && rubrique.toUpperCase()}</Typography>
                </Grid2>}
                <Grid2 container spacing={2} item size={12}>
                    {articles && articles.slice(0, 8).map((article) => (
                        <Grid2 item size={{ xs: 12, md: 3 }}>
                            <ArticleItem article={article} />
                        </Grid2>
                    ))}
                </Grid2>
            </Grid2>
        </Container>
    )
}

export default MoreArticles