import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import NavItem from "./NavItem";
import { useScrollTrigger, useTheme, Avatar } from "@mui/material";
import { menuList } from "../menuList";
import { auth } from "../../../firebase";

const Topbar = ({ onSidebarOpen }) => {
  const theme = useTheme();
  const user = localStorage.getItem("userID");
  const currentUser = auth.currentUser;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  const [activeLink, setActiveLink] = React.useState("");
  React.useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.main }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ ml: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              onClick={() => onSidebarOpen()}
              aria-label="Menu"
              variant={"outlined"}
            >
              <MenuIcon sx={{ color: 'white' }} />
            </IconButton>
          </Box>

          <Typography
            component="a"
            sx={{ mr: 0.5, mt: 1, textAlign: "center", flexGrow: 1 }}
            href={"/"}
          >
            <img src={"/images/logo.png"} alt="logo" width={120} />
          </Typography>
          <Box
            sx={{
              ml: 2,
              flexGrow: 1,
              justifyContent: "flex-start",
              display: { xs: "none", md: "flex" },
            }}
          >
            {menuList.map((page) =>
              page.items ? (
                <Box sx={{ m: 3 }}>
                  <NavItem title={page.name} items={page.items} />
                </Box>
              ) : (
                <Button
                  component="a"
                  href={page.href}
                  sx={{
                    color:
                      activeLink === page.href
                        ? theme.palette.secondary.main
                        : 'white',
                    fontSize: 15,
                    m: 1,
                    "&:hover": {
                      color: theme.palette.secondary.main,
                    },
                  }}
                >
                  {page.name}
                </Button>
              )
            )}
            {user && user ? (
              <Box sx={{ p: 2 }}>
                <Avatar
                  alt={currentUser && currentUser.displayName}
                  src={currentUser && currentUser.photoURL}
                  component={"a"}
                  href="/profil"
                />
              </Box>
            ) : (
              <Button
                component="a"
                href="/se-connecter"
                size="small"
                variant="contained"
                sx={{ borderRadius: 10, ml: 4, px: 4, backgroundColor: '#006ead' }}
              >
                Se connecter
              </Button>
            )}
          </Box>
          {user && (
            <Box sx={{ p: 2, display: { md: "none", xs: "flex" } }}>
              <Avatar
                alt={currentUser && currentUser.displayName}
                src={currentUser && currentUser.photoURL}
                component={"a"}
                href="/profil"
              />
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Topbar;
