import { Box, Button, CardMedia, Container, Divider, Grid2, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import {
    collection,
    query,
    where,
    orderBy,
    onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import ArticleItem from '../article/Item';
import { useNavigate } from 'react-router-dom';
import LatestNews from '../includes/LatestNews';

const ALaUne = () => {
    const [articles, setArticles] = useState([]);
    const theme = useTheme()
    const navigate = useNavigate()

    useEffect(() => {
        if (!db) return; // Attendre que Firestore soit initialisé
        const colRef = collection(db, "articles");
        const q = query(
            colRef,
            orderBy("createdAt", "desc")
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            let content = [];
            if (snapshot.docs) {
                snapshot.docs.forEach((doc) =>
                    content.push({ id: doc.id, ...doc.data() })
                );
                setArticles(content);
            }
        });

        // Unsubscribe from events when no longer in use
        return () => unsubscribe();
    }, []);

    return (
        <Container>
            <Grid2 container spacing={2} sx={{ p: { md: 5 }, py: 5 }}>
                {articles && articles.length > 0 && <Grid2 item size={12}>
                    <Typography variant='h3' color={'primary'}>A LA UNE</Typography>
                </Grid2>}
                <Grid2 container spacing={2} sx={{ p: 3 }} item size={{ xs: 12, md: 8 }}>
                    <Grid2 item xs={12}>
                        {articles && articles.slice(0, 1).map(article => (
                            <ArticleItem aLaUne={true} article={article} />
                        ))}
                    </Grid2>
                    <Grid2 container item spacing={2} size={12}>
                        {articles && articles.slice(1, 5).map((article) => (
                            <Grid2 item size={6}>
                                <ArticleItem article={article} />
                            </Grid2>
                        ))}
                    </Grid2>
                </Grid2>


                <LatestNews />

            </Grid2>
        </Container>
    )
}

export default ALaUne