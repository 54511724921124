import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid2, Link, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginWithGoogle, register } from "../../redux/actions/authActions";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";

const mapState = ({ auth }) => ({
  loading: auth.loading,
});

function RegisterForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({});
  const { loading } = useSelector(mapState);
  const [errorMessage, setErrorMessage] = useState(null);

  const redirect = () => navigate("/profil");

  const handleChange = (e) =>
    setCredentials({ ...credentials, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    credentials.password === credentials.passwordConfirm
      ? dispatch(register(credentials, redirect))
      : setErrorMessage("Entrez un mot de passe correct!");
  };

  const handleLoginWithGoogle = () => dispatch(loginWithGoogle(redirect));

  return (
    <form onSubmit={handleSubmit}>
      <Grid2 container spacing={2}>
        {/* <Grid item xs={12}>
          <Logo width={150} />
        </Grid> */}
        <Grid2 sx={{ marginTop: -2 }} item size={12}>
          <Typography
            color="textPrimary"
            sx={{ fontWeight: "600" }}
            variant="h4"
          >
            Créer un compte
          </Typography>
        </Grid2>
        <Grid2 item size={12}>
          <TextField
            name="email"
            label="E-mail"
            required
            fullWidth
            variant="standard"
            type="email"
            onChange={handleChange}
          />
        </Grid2>

        <Grid2 item size={12}>
          <TextField
            name="password"
            label="Mot de passe"
            fullWidth
            required
            variant="standard"
            type="password"
            onChange={handleChange}
          />
        </Grid2>

        <Grid2 item size={12}>
          <TextField
            name="passwordConfirm"
            label="Confirmer le mot de passe"
            fullWidth
            required
            variant="standard"
            type="password"
            onChange={handleChange}
          />
        </Grid2>

        {errorMessage && (
          <Grid2 item size={12}>
            <Typography textAlign={"center"} color={"error"}>
              {errorMessage}{" "}
            </Typography>
          </Grid2>
        )}
        <Grid2 item size={12}>
          <LoadingButton
            type="submit"
            loading={loading}
            variant="contained"
            sx={{ color: "#fff", borderRadius: 10, backgroundColor: '#006ead' }}
            color="primary"
            fullWidth
          >
            Connexion
          </LoadingButton>
        </Grid2>
        <Grid2 item size={12}>
          <Typography textAlign={"center"}>Ou</Typography>
        </Grid2>
        <Grid2 item size={12}>
          <Button
            onClick={handleLoginWithGoogle}
            variant="outlined"
            sx={{ borderRadius: 10 }}
            color="primary"
            fullWidth
            startIcon={<GoogleIcon />}
          >
            Connexion avec Google
          </Button>
        </Grid2>
        <Grid2 item size={12}>
          <Typography color={"textSecondary"} textAlign={"center"}>
            Vous avez déja un compte? <a href="/se-connecter">Connectez-vous</a>
          </Typography>
        </Grid2>
      </Grid2>
    </form>
  );
}

export default RegisterForm;
