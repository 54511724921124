import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";

export const subscribe = (user) => {
    return (dispatch) => {
        const colRef = collection(db, "subsribers");

        dispatch({ type: "SUBSCRIBE_LOADING", payload: true });

        addDoc(colRef, {
            ...user,
            createdAt: new Date().getTime(),
        })
            .then(() => dispatch({ type: "SUBSCRIBE_SUCCESS" }))
            .catch((err) => dispatch({ type: "SUBSCRIBE_ERROR" }));
    };
};
