import { Container, Grid2, Typography } from '@mui/material'
import React from 'react'

const PubBanner = () => {
    return (
        <Container sx={{ mt: 5 }}>
            <Grid2 container spacing={2}>
                <Grid2 item size={12}>
                    <Typography>
                        <img src='/images/kobo-banner.png' width={'100%'} alt='Kobo Art Festival' />
                    </Typography>
                </Grid2>
            </Grid2>
        </Container>
    )
}

export default PubBanner