import React from 'react'
import { Route, Routes } from "react-router-dom";
import routes from "./routes";
import NotFound from "./pages/notFound";
import Layout from "./components/Layout";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { store } from "./redux";
import theme from "./theme";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Layout>
          <Routes>
            <Route path="*" element={<NotFound />} />
            {routes.map(({ path, component }, index) => (
              <Route key={index} path={path} element={component} />
            ))}
          </Routes>
        </Layout>
      </ThemeProvider>
    </Provider>

  );
}

export default App;
