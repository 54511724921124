import { Typography } from '@mui/material'
import React from 'react'

const PubSquare = () => {
    return (
        <Typography>
            <img src='/images/kobo-mobile.png' width={'100%'} alt='Kobo Art Festival' />
        </Typography>
    )
}

export default PubSquare