import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore, persistentLocalCache, memoryLocalCache } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyD7ponX4SI4wq4hT6cOJKt8EbC0Q279JSw",
    authDomain: "voice-of-congo.firebaseapp.com",
    projectId: "voice-of-congo",
    storageBucket: "voice-of-congo.appspot.com",
    messagingSenderId: "633328791033",
    appId: "1:633328791033:web:7355ed532d76c29efa6128",
    measurementId: "G-2KY9HSEME1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
//export const db = getFirestore(app);

// Initialize Firestore with persistent local cache
export const db = initializeFirestore(app, {
    localCache: persistentLocalCache()
});
