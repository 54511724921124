import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import newsletterReducer from "./newsletterReducer";
import userReducer from "./userReducer";


export const rootReducer = combineReducers({
  auth: authReducer,
  newsletter: newsletterReducer,
  user: userReducer
});
