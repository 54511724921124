import {
  Avatar,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { uploadCV } from "../../redux/actions/userActions";

const mapState = ({ user }) => ({
  loading: user.loading,
});

const CV = () => {
  const [user, setUser] = useState({});
  const { loading } = useSelector(mapState);
  const dispatch = useDispatch();

  const loadFile = async (event) => {
    var reader = new FileReader();

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
      setUser({ ...user, cv: event.target.files[0] });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(uploadCV(user.cv));
  };

  return (
    <Container sx={{ p: 5 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            container
            direction={"row"}
            justifyContent={"center"}
          >
            <input
              accept="application/pdf"
              style={{ display: "none" }}
              id="import"
              type="file"
              onChange={loadFile}
            />
            <label htmlFor="import">
              <Button
                //startIcon={<CameraAlt />}
                component="span"
              >
                Importer mon CV
              </Button>
            </label>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign={"center"}>
              {user.cv && user.cv.name}
            </Typography>
            <Typography textAlign={"center"}>
              <LoadingButton
                type="submit"
                loading={loading}
                sx={{
                  width: { md: "50%", xs: "100%" },
                  mt: 4,
                  borderRadius: 10,
                }}
                fullWidth
                variant="contained"
              >
                Valider
              </LoadingButton>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default CV;
