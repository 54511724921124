import React from 'react';
import Articles from "./pages/article"
import ArticleDetails from "./pages/article/Details"
import Culture from "./pages/culture"
import Gospel from "./pages/gospel"
import Home from "./pages/home"
import Muzik from "./pages/muzik"
import Sport from "./pages/sport"
import Offers from './pages/emploi';
import OfferDetails from './pages/emploi/Details';
import Register from './pages/auth/Register';
import Profile from './pages/profile';
import Login from './pages/auth';



const routes = [
  {
    name: "Home",
    path: "/",
    component: React.createElement(Home, null),
  },
  {
    name: "Articles",
    path: "/articles",
    component: React.createElement(Articles, null),
  },
  {
    name: "Article Details",
    path: "/articles/:articleID",
    component: React.createElement(ArticleDetails, null),
  },

  {
    name: "Culture",
    path: "/culture",
    component: React.createElement(Culture, null),
  },
  {
    name: "Gospel",
    path: "/gospel",
    component: React.createElement(Gospel, null),
  },
  {
    name: "Muzik",
    path: "/musique",
    component: React.createElement(Muzik, null),
  },
  {
    name: "Sport",
    path: "/sport",
    component: React.createElement(Sport, null),
  },

  {
    path: "/offres-emploi",
    component: React.createElement(Offers, null),
  },
  {
    path: "/offres/:offerID",
    component: React.createElement(OfferDetails, null),
  },
  {
    path: "/se-connecter",
    component: React.createElement(Login, null),
  },
  {
    path: "/creer-un-compte",
    component: React.createElement(Register, null),
  },
  {
    path: "/profil",
    component: React.createElement(Profile, null),
  },

]

export default routes