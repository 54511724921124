import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import ReactPlayer from 'react-player'
import parse from 'html-react-parser'
import { rubriqueColor } from '../../functions'
import moment from 'moment'

const ArticleItem = ({ article, aLaUne }) => {
    return (
        <Box>
            <Box>
                {article.video ? <ReactPlayer
                    url={article.video}
                    height="400px"
                    width={"100%"}
                    controls={true}
                /> : (
                    <CardMedia
                        component="img"
                        height={aLaUne ? "400" : '200'}
                        image={article.images ? article.images[0] : article.image}
                        alt="image"
                        sx={{ backgroundColor: '#eee' }}
                    />
                )}

            </Box>
            <Typography fontSize={14} color={'white'} sx={{ my: 1, backgroundColor: rubriqueColor(article.rubrique), width: '40%', textAlign: 'center' }} fontWeight={'500'}>{article.rubrique}</Typography>
            <Typography fontWeight={'600'} fontSize={aLaUne ? 30 : 17} color={'textPrimary'} sx={{ display: 'block', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }} variant='h6' component={'a'} href={'/articles/' + article.id}>{article.title}</Typography>
            {aLaUne && <Typography color={'textSecondary'} sx={{ display: 'block', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }} component={'a'} href={'/articles/' + article.id}>
                {parse(article.content.slice(0, 200))}
            </Typography>}
            <Typography fontSize={12} color={'text.secondary'}> {moment(article.createdAt).calendar()}</Typography>
        </Box>
    )
}

export default ArticleItem