export const rubriqueColor = rubrique => {
    switch (rubrique) {
        case 'Actualité':
            return '#006ead';
        case 'Buzz':
            return '#e80607';
        case 'Musique':
            return '#e80607';
        case 'Annonce':
            return '#e80607';
        case 'Culture':
            return '#fcdc33';
        case 'Politique':
            return '#006ead';
        case 'Société':
            return '#006ead';
        case 'Sport':
            return '#63b74c';
        case 'Evénement':
            return '#e80607';
        default:
            return '#006ead';
    }
}