import React from 'react'; // Assurez-vous que cette ligne est présente
import * as _fa from 'react-icons/fa';


export const footerData = {
  entreprise: {
    title: "LV SPA",
    subtitles: [
      {
        title: "Tarifs",
        path: "/tarifs",
      },
      {
        title: "A propos de nous",
        path: "/a-propos",
      },

      {
        title: "Mentions Légales",
        path: "/mentions",
      },
    ],
  },

  communnity: {
    title: "Contact",
    socials: [
      {
        name: "Facebook",
        link: "https://www.facebook.com/VoiceOfCongo/",
        icon: React.createElement(_fa.FaFacebook, { style: { color: 'white' } }),
      },
      {
        name: "Instagram",
        link: "https://www.instagram.com/voiceofcongo",
        icon: React.createElement(_fa.FaInstagram, { style: { color: 'white' } }),
      },
      {
        name: "Tiktok",
        link: "https://www.tiktok.com/@voiceofcongo",
        icon: React.createElement(_fa.FaTiktok, { style: { color: 'white' } }),
      },
      {
        name: "Twitter",
        link: "https://x.com/VoiceOfCongo",
        icon: React.createElement(_fa.FaTwitter, { style: { color: 'white' } }),
      },
      {
        name: "SnapChat",
        link: "https://snapchat.com/",
        icon: React.createElement(_fa.FaSnapchat, { style: { color: 'white' } }),
      },
      {
        name: "Threads",
        link: "https://www.linkedin.com/@voiceofcongo",
        icon: React.createElement(_fa.FaLinkedin, { style: { color: 'white' } }),
      },
    ],
  },
};

export const informations = [
  {
    title: "Actualités",
    href: "/actu",
  },
  {
    title: "Buzz",
    href: "/buzz",
  },
  {
    title: "Culture",
    href: "/culture",
  },
  {
    title: "Musique",
    href: "/culture",
  },
  {
    title: "Politique",
    href: "/politique",
  },
  {
    title: "Société",
    href: "/societe",
  },
  {
    title: "Sport",
    href: "/sport",
  },

];

export const services = [
  {
    name: "Diffusion (post)",
  },
  {
    name: "Couverture événementielle",
  },
  {
    name: "Rédaction d'article",
  },
  {
    name: "Webinaire",
  },
  {
    name: "Interview",
  },
  {
    name: "Réportage",
  },
]

export const BASE_URL = 'https://voiceofcongo.net/'


