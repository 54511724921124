import { Box, Grid2 } from '@mui/material'
import React from 'react'
import Slider from 'react-slick'

const ImageSlider = ({ images }) => {
    var settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <Grid2 item size={12}>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <Box
                        key={index}
                        sx={{
                            height: 400,
                            px: { md: 10, xs: 6 },
                            pt: { xs: 6, md: false },
                            overflow: "hidden",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundImage: `url(${image}) `,
                        }}
                    ></Box>
                ))}
            </Slider>
        </Grid2>
    )
}

export default ImageSlider