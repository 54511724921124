import React, { useEffect, useState } from 'react'
import { Box, CardMedia, Container, Grid2, Typography } from '@mui/material'
import {
    collection,
    query,
    where,
    orderBy,
    onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import moment from 'moment';
import ArticleItem from '../article/Item';

const HomeMuzik = () => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (!db) return; // Attendre que Firestore soit initialisé
        const colRef = collection(db, "articles");
        const q = query(
            colRef,
            where("rubrique", "==", "Musique"),
            orderBy("createdAt", "desc"),

        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            let content = [];
            if (snapshot.docs) {
                snapshot.docs.forEach((doc) =>
                    content.push({ id: doc.id, ...doc.data() })
                );
                setArticles(content);
            }
        });

        // Unsubscribe from events when no longer in use
        return () => unsubscribe();
    }, []);
    return (
        <Box sx={{ backgroundColor: 'rgba(255, 205, 205, 0.3)', py: 3 }}>
            <Container>
                <Grid2 container spacing={2} sx={{ p: { md: 5 } }}>
                    {articles && articles.length > 0 && <Grid2 sx={{ my: 3 }} item size={12}>
                        <Typography variant='h3' color={'primary'}>VOC Muzik</Typography>
                        <Typography fontSize={17}>Toute l'actualité musicale congolaise.</Typography>
                    </Grid2>}

                    <Grid2 container spacing={2} item size={12}>
                        {articles.slice(0, 8).map((article) => (
                            <Grid2 item size={{ xs: 12, md: 3 }}>
                                <ArticleItem article={article} />
                            </Grid2>
                        ))}

                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default HomeMuzik