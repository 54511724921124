export const menuList = [
  {
    name: "A la Une",
    href: "/",
  },
  {
    name: "Toute l'actualité",
    href: "/articles",
  },
  {
    name: "VOC Kultur",
    href: "/culture",
  },
  {
    name: "VOC Muzik",
    href: "/musique",
  },
  {
    name: "VOC Gospel",
    href: "/gospel",
  },
  {
    name: "VOC Sport",
    href: "/sport",
  },
  {
    name: "Offres d'emploi",
    href: "/offres-emploi",
  },
  // {
  //   name: "A propos",
  //   href: '/',
  //   items: [
  //     {
  //       title: 'Découvrez-nous',
  //     },
  //     {
  //       title: "Notre équipe",
  //     },
  //     {
  //       title: "Commander nos prestations",
  //     },
  //     {
  //       title: "Réjoignez-nous",
  //     },
  //   ]
  // },

];
