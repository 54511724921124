// BouncingLogo.js
import React from 'react';

const BouncingLogo = () => {
    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Full viewport height
        },
        logo: {
            width: '200px', // Adjust size as needed
            animation: 'bounce 1s infinite',
        },
    };

    return (
        <div style={styles.container}>
            <img
                src={'/images/logo-secondary.png'}
                alt="Logo"
                style={styles.logo}
            />
            <style>
                {`
                    @keyframes bounce {
                        0%, 20%, 50%, 80%, 100% {
                            transform: translateY(0);
                        }
                        40% {
                            transform: translateY(-30px); // Bounce height
                        }
                        60% {
                            transform: translateY(-15px); // Bounce height
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default BouncingLogo;
