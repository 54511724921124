import { Box, Button, Container, Grid2, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { subscribe } from '../../redux/actions/newsletterActions'

const mapState = ({ newsletter }) => ({
    loading: newsletter.loading
})

const NewsletterForm = () => {
    const { loading } = useSelector(mapState)
    const dispatch = useDispatch()
    const [subscriber, setSubscriber] = useState()

    const handleSubmit = e => {
        e.preventDefault()
        dispatch(subscribe(subscriber))
    }

    const handleChange = (e) =>
        setSubscriber({ ...subscriber, [e.target.name]: e.target.value });

    return (
        <Container sx={{ p: 5 }}>
            <form onSubmit={handleSubmit}>
                <Grid2 container spacing={2}>
                    <Grid2 container direction={'row'} justifyContent={'center'} alignContent={'center'} item size={{ xs: 12, md: 6 }}>
                        <Box>
                            <img src='/images/template.png' width={'40%'} />
                        </Box>
                    </Grid2>
                    <Grid2 container spacing={2} item size={{ xs: 12, md: 6 }}>
                        <Grid2 item xs={12}>
                            <Typography variant='h3'>Abonnez-vous pour plus de nouvelles !</Typography>
                        </Grid2>
                        <Grid2 item size={12}>
                            <TextField
                                name='name'
                                label="Votre nom"
                                fullWidth
                                variant='outlined'
                                onChange={handleChange}
                            />
                        </Grid2>
                        <Grid2 item size={12}>
                            <TextField
                                name='email'
                                label="Entrez votre adresse mail"
                                fullWidth
                                variant='outlined'
                                onChange={handleChange}
                            />
                        </Grid2>
                        <Grid2 item size={6}>
                            <Button type='submit' disabled={loading} fullWidth variant='contained'>S'abonner</Button>
                        </Grid2>
                    </Grid2>

                </Grid2>
            </form>

        </Container>
    )
}

export default NewsletterForm