import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#040929",
        },
        secondary: {
            main: "#006ead",
        },
        thirth: {
            main: '#fcdc33'
        }
    },
    typography: {
        fontFamily: "Montserrat",
        button: {
            textTransform: "normal",
        },
        h3: {
            fontWeight: "700",
            fontSize: 35
        },
    },
});

export default theme;
